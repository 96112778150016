$color-white: #fff;
$color-black: #0f1b24;
$color1: #3498db;
$color2: #3ab0ff;
$color3: #1b689c;
$color4: #515151;
$color5: #c0c0c0;
$color6: #e5e5e5;
$color7: #f0f0f0;

$font1: 'Inter', sans-serif;
$font2: 'BebasNeueCyrillic', sans-serif;

// Sass Breakpoint Mixin
@mixin breakpoint($point) {
	@if $point == psm {
		@media (min-width: 480px) { @content; }
	}
	@else if $point == pmd {
		@media (min-width: 768px) { @content; }
	}
	@else if $point == plg {
		@media (min-width: 1000px) { @content; }
	}
	@else if $point == pxl {
		@media (min-width: 1260px) { @content; }
	}
	@else if $point { // allows custom breakpoints
		@media (min-width: $point) { @content; }
	}
}
// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss, only before @import bootstrap variables!"
$grid-gutter-width: 20px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 480px, md: 768px, lg: 1000px, xl: 1260px);
$container-max-widths: (sm: 480px, md: 768px, lg: 1000px, xl: 1260px);

$gutter: $grid-gutter-width;
@import 'fonts';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'reset';

//@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
//@import '../../node_modules/bootstrap/scss/variables';

html {
	font-size: 15px;
	@include breakpoint(psm) {
		font-size: 17px;
	}
	&.ofh {
		overflow: hidden;
	}
}
body {
	min-width: 360px;
	color: $color-black;
	font-family: $font1;
	line-height: 1.3;
	background-color: $color6;
}

.container {
	height: 100%;
	padding-right: 20px;
	padding-left: 20px;
}
.row-s5 {
	margin-right: -5px;
	margin-left: -5px;
}
.col-lg-2_4 {
	position: relative;
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	@include breakpoint(plg) {
		flex: 0 0 20%;
		max-width: 20%;
	}
}
.col-s5 {
	padding-right: 5px;
	padding-left: 5px;
}

.bg-layout-cover {
	background: center/cover no-repeat;
}
.bg-layout-contain {
	background: center/contain no-repeat;
}
.fit-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.fit-contain {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.svg-icon {
	display: block;
}

.font1 {
	font-family: $font1;
}
.font2 {
	font-family: $font2;
}
h1 {
	color: $color-white;
	font-family: $font2;
	font-size: 62px;
	line-height: 1;
	@include breakpoint(psm) {
		font-size: 92px;
	}
	@include breakpoint(pxl) {
		font-size: 103px;
	}
}
h2 {
	font-family: $font2;
	font-size: 48px;
	line-height: 1;
	@include breakpoint(pmd) {
		font-size: 64px;
	}
}
h3 {
	font-family: $font2;
	font-size: 42px;
	line-height: 1;
	@include breakpoint(psm) {
		font-size: 48px;
	}
}
h4 {
	font-family: $font2;
	font-size: 32px;
	line-height: 1;
}

.lh1 {
	line-height: 1;
}
.h-0 {
	height: 0;
}
.br6 {
	border-radius: 6px;
}
.mt-10 {
	margin-top: 10px;
}
.spec-mt-10 {
	margin-top: 10px;
	@include breakpoint(pmd) {
		margin-top: 0;
	}
}
.spec-mt-10__2 {
	margin-top: 10px;
	@include breakpoint(plg) {
		margin-top: 0;
	}
}
.ptb-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}
.spec-mt-20 {
	margin-top: 10px;
	@include breakpoint(pmd) {
		margin-top: 20px;
	}
}
.accent-color {
	color: $color1;
}
.accent-p {
	font-family: $font2;
	font-size: 32px;
	line-height: 1;
}

.blue-font-link {
	color: $color1 !important;
	&:hover {
		color: $color2 !important;
	}
	&:active {
		color: $color3 !important;
	}
}
.blue-btn {
	color: $color-white;
	font-weight: 700;
	text-transform: uppercase;
	background-color: $color1;
	&:hover {
		background-color: $color2;
	}
	&:active {
		background-color: $color3;
	}
}
.white-btn {
	color: $color1;
	font-weight: 700;
	text-transform: uppercase;
	background-color: $color-white;
	&:hover {
		color: $color-white;
		background-color: $color2;
	}
	&:active {
		color: $color-white;
		background-color: $color3;
	}
}

.z-index-1 {
	z-index: 1;
}


/* input-placeholder */
input::-webkit-input-placeholder {
	color: $color5;
	opacity: 1;
	transition: opacity 0.3s ease;
}
input::-moz-placeholder {
	color: $color5;
	opacity: 1;
	transition: opacity 0.3s ease;
}
input:-ms-input-placeholder {
	color: $color5;
	opacity: 1;
	transition: opacity 0.3s ease;
}
input:focus::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input:focus::-moz-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input:focus:-ms-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea::-webkit-input-placeholder {
	color: $color5;
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea::-moz-placeholder {
	color: $color5;
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea:-ms-input-placeholder {
	color: $color5;
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea:focus::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea:focus::-moz-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea:focus:-ms-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input::-ms-clear {
	display: none;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
	display: none;
}


.socials {
	display: flex;
	align-items: center;
	li {
		+ li {
			margin-left: 10px;
		}
		a {
			display: block;
			width: 30px;
			height: 30px;
		}
	}
}
.status {
	line-height: 1;
	vertical-align: middle;
	border: 1px solid $color4;
	padding: 5px 15px;
	border-radius: 15px;
	margin-right: 12px;
	@include breakpoint(pmd) {
		color: $color-white;
	}
	&::before {
		content: '';
		display: inline-block;
		width: 12px;
		height: 12px;
		vertical-align: middle;
		background-color: #48c95f;
		border-radius: 50%;
		margin: 0 8px 2px 0;
	}
}

.tbl-wrap {
	margin-right: -20px;
	margin-left: -20px;
	@include breakpoint(pmd) {
		margin-right: 0;
		margin-left: 0;
	}
}
.tbl {
	width: 100%;
	thead {
		border-bottom: 2px solid $color1;
	}
	th, td {
		text-align: right;
		padding: 9px 10px;
		&:first-child {
			text-align: left;
			padding-left: 20px;
			@include breakpoint(psm) {
				border-radius: 6px 0 0 6px;
			}
		}
		&:last-child {
			padding-right: 20px;
			@include breakpoint(psm) {
				border-radius: 0 6px 6px 0;
			}
		}
	}
	th {
		font-size: 15px;
		font-weight: 700;
		text-transform: uppercase;
	}
	tbody {
		tr {
			&:first-child {
				td {
					height: 10px;
					padding: 0;
				}
			}
			&:nth-child(even) {
				td {
					background-color: $color-white;
				}
			}
		}
	}
}

/* fields */
.field {
	width: 100%;
	height: 55px;
	padding: 0 17px;
	border-bottom: 1px solid $color5;
}
.field-select {
	width: 100%;
}
.jq-selectbox__select {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 55px;
	padding: 0 17px;
	border-bottom: 1px solid $color5;
	cursor: pointer;
}
.jq-selectbox__trigger {
	flex-shrink: 0;
	width: 14px;
	height: 9px;
	background: url(../img/arr_down.png) center/contain no-repeat;
	margin-left: 10px;
	&.mod {
		transform: rotate(180deg);
	}
}
.jq-selectbox__dropdown {
	max-width: 100%;
	font-size: 15px;
	background-color: $color-white;
	padding: 5px 0;
	border-radius: 6px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.jq-selectbox li,
.jq-select-multiple li {
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 5px 17px;
	cursor: pointer;
	&:hover {
		color: $color1;
	}
}
.simulate {
	flex-shrink: 0;
	display: block;
	width: 24px;
	height: 24px;
	padding: 4px;
	border: 2px solid $color-black;
	border-radius: 50%;
	cursor: pointer;
	span {
		display: block;
		width: 100%;
		height: 100%;
		background-color: transparent;
		border-radius: 50%;
	}
}
.radio-label {
	padding-left: 9px;
	cursor: pointer;
}
.field-radio {
	display: none;
	&:checked {
		+ .simulate {
			border-color: $color1;
			span {
				background-color: $color1;
			}
		}
	}
}
.jq-file {
	width: 100%;
	input {
		width: 0;
		height: 0;
	}
}
.jq-file__name {
	display: none;
}
.jq-file__browse {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 55px;
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	background-color: $color-white;
	border: 2px solid $color1;
	border-radius: 6px;
	cursor: pointer;
	transition: background-color 0.2s;
	&:hover {
		color: $color-white;
		background-color: $color1;
	}
}
.field-file {
	line-height: 0;
}
/* </fields */


/* header */
header {
	position: relative;
	width: 100%;
	z-index: 200;
	overflow: hidden;
	@include breakpoint(plg) {
		overflow: visible;
	}
}
.top-header {
	background-color: $color-white;
	padding: 20px 0;
}
.btm-header {
	height: 74px;
	background-color: $color-black;
}
.logo {
	margin-right: 40px;
	@include breakpoint(psm) {
		margin-right: 20px;
	}
}
.logo-slogan {
	max-width: 130px;
	font-size: 17px;
	@include breakpoint(plg) {
		max-width: 240px;
		font-size: 20px;
	}
}
.tel-link {
	display: inline-block;
	font-size: 32px;
	margin-bottom: 5px;
}
.icon-mail,
.icon-clock {
	width: 24px;
	height: 24px;
	fill: none;
	stroke: $color-black;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
}
#for-clbbtn {
	width: 100%;
	@include breakpoint(pmd) {
		width: auto;
	}
}
.clbbtn {
	font-size: 15px;
	width: 215px;
	height: 50px;
	@include breakpoint(pxl) {
		height: 58px;
	}
}
.h-menu {
	display: flex;
	align-items: center;
	color: $color-white;
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	background-color: $color-black;
	li {
		a {
			&:hover {
				color: $color2;
			}
		}
	}
	> li {
		white-space: nowrap;
		line-height: 3;
		+ li {
			margin-left: 36px;
		}
		&.ext {
			position: relative;
			> a {
				display: flex;
				align-items: center;
				&::after {
					content: '';
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 7px 4px;
					border-color: transparent;
					border-top-color: $color1;
					border-bottom-width: 0;
					margin-left: 6px;
				}
			}
		}
	}
	.h-submenu,
	.h-subsubmenu {
		display: none;
		position: absolute;
		top: 100%;
		left: -20px;
		color: $color-black;
		line-height: 1;
		background-color: $color-white;
		padding: 15px 20px;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.16);
		li {
			width: 100%;
			padding: 10px 0;
			a {
				&:hover {
					color: $color1;
				}
			}
		}
		> li {
			&.ext {
				position: relative;
				> a {
					display: flex;
					justify-content: space-between;
					align-items: center;
					&::after {
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 4px 7px;
						border-color: transparent;
						border-left-color: $color1;
						border-right-width: 0;
						margin-left: 30px;
					}
				}
			}
		}
	}
	.h-subsubmenu {
		left: calc(100% + 10px);
		top: -10px;
	}
}
.burger {
	width: 60px;
	height: 50px;
	background-color: transparent;
	border: 2px solid $color1;
	margin-left: 20px;
	.svg-icon {
		display: inline-block;
		fill: none;
		stroke: $color-white;
		stroke-width: 3;
		stroke-linecap: round;
		stroke-linejoin: round;
	}
	.icon-burger {
		width: 22px;
		height: 16px;
	}
	.icon-cross {
		display: none;
		width: 16px;
		height: 16px;
	}
	&.active {
		background-color: $color1;
		.icon-burger {
			display: none;
		}
		.icon-cross {
			display: inline-block;
		}
	}
}
.rolled-menu-wr {
	width: 360px;
	position: fixed;
	right: -360px;
	bottom: 0;
	z-index: 100;
	overflow: auto;
	background-color: $color-white;
	padding: 45px 40px;
	transition: right 0.25s;
	&.rolled-out {
		right: 0;
	}
}
.rolled-menu {
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1;
	> li {
		+ li {
			margin-top: 36px;
		}
	}
	.r-submenu,
	.r-subsubmenu {
		font-size: 20px;
		font-weight: 400;
		text-transform: none;
		padding-left: 20px;
		> li {
			margin-top: 25px;
		}
	}
}
.cover {
	display: none;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	background-color: $color-black;
	opacity: 0.9;
}
/* </header */


/* main */
// main-screen
.main-screen {
	height: 480px;
	padding: 60px 0 40px 0;
	background: linear-gradient(78.4deg, rgba(0, 0, 0, 0.59) 16.68%, rgba(0, 0, 0, 0.46) 87.43%),
		url(../img/bg.jpg) center/cover no-repeat;
	@include breakpoint(psm) {
		height: 650px;
	}
	@include breakpoint(plg) {
		padding-top: 0;
	}
	@include breakpoint(pxl) {
		height: 740px;
	}
}
.sub-h1 {
	color: $color1;
	font-size: 48px;
	margin-top: 10px;
}
.slogan-list {
	color: $color-white;
	font-size: 17px;
	margin: 40px 0 0 46px;
	@include breakpoint(psm) {
		font-size: 24px;
	}
	li {
		+ li {
			margin-top: 10px;
		}
		&::before {
			content: '';
			display: inline-block;
			width: 26px;
			height: 21px;
			background: url(../img/check.png) center/contain no-repeat;
			margin: 0 20px 0 -46px;
		}
	}
}
.order-form {
	.top-block {
		background-color: $color-white;
		padding: 35px 20px 30px 20px;
		@include breakpoint(pxl) {
			padding: 35px 30px 30px 30px;
		}
	}
	.submit-btn {
		width: 100%;
		height: 64px;
	}
}
// </main-screen
// tabs
.tab {
	height: 100%;
	background-color: $color-white;
	border-radius: 6px;
	.icon-pict {
		flex-shrink: 0;
		width: 64px;
		height: 64px;
	}
	.tab-text {
		display: flex;
		align-items: center;
	}
}
.tabs1 {
	padding: 40px 0;
	@include breakpoint(pmd) {
		padding: 0 0 80px 0;
		margin-top: -231px;
	}
	@include breakpoint(plg) {
		padding: 0 0 40px 0;
		margin-top: -52px;
	}
	@include breakpoint(pxl) {
		padding: 0 0 80px 0;
		margin-top: -63px;
	}
}
.tab1 {
	display: flex;
	height: 104px;
	padding: 20px;
	@include breakpoint(pmd) {
		display: block;
		height: auto;
	}
	@include breakpoint(plg) {
		display: flex;
		height: 104px;
	}
	@include breakpoint(pxl) {
		height: 126px;
		padding: 31px 34px;
	}
	.icon-pict {
		margin: 0 14px 0 0;
		@include breakpoint(pmd) {
			margin: 0 0 14px 0;
		}
		@include breakpoint(plg) {
			margin: 0 14px 0 0;
		}
		@include breakpoint(pxl) {
			margin-right: 24px;
		}
	}
	.tab-text {
		padding: 0 0 0 14px;
		border-left: 1px solid $color5;
		@include breakpoint(pmd) {
			padding: 14px 0 0 0;
			border-left: none;
			border-top: 1px solid $color5;
		}
		@include breakpoint(plg) {
			padding: 0 0 0 14px;
			border-top: none;
			border-left: 1px solid $color5;
		}
		@include breakpoint(pxl) {
			padding-left: 24px;
		}
	}
	.accent-p {
		margin-bottom: 4px;
	}
}
.tabs2 {
	padding: 30px 0;
	@include breakpoint(pmd) {
		padding: 40px 0;
	}
	@include breakpoint(pxl) {
		padding: 80px 0 40px 0;
	}
}
.tab2 {
	display: flex;
	padding: 20px;
	@include breakpoint(plg) {
		display: block;
		padding: 30px 20px;
	}
	.icon-pict {
		margin: 0 14px 0 0;
		@include breakpoint(pmd) {
			margin: 0 24px 0 0;
		}
		@include breakpoint(plg) {
			margin: 0 0 24px 0;
		}
	}
	.tab-text {
		padding: 0 0 0 14px;
		border-left: 1px solid $color5;
		@include breakpoint(pmd) {
			padding: 0 0 0 24px;
		}
		@include breakpoint(plg) {
			padding: 24px 0 0 0;
			border-left: none;
			border-top: 1px solid $color5;
		}
	}
	.accent-p {
		margin-bottom: 4px;
	}
}
.tabs3, .tabs4 {
	padding: 30px 0;
	@include breakpoint(plg) {
		padding: 40px 0;
	}
	@include breakpoint(pxl) {
		padding: 80px 0 40px 0;
	}
	h2 {
		margin-bottom: 30px;
		@include breakpoint(pmd) {
			margin-bottom: 40px;
		}
	}
}
.tab3 {
	display: flex;
	padding: 20px;
	@include breakpoint(plg) {
		padding: 31px 34px;
	}
	@include breakpoint(pxl) {
		align-items: center;
	}
	.icon-pict {
		margin: 0 14px 0 0;
		@include breakpoint(pmd) {
			margin: 0 24px 0 0;
		}
	}
	.tab-text {
		padding: 0 0 0 14px;
		border-left: 1px solid $color5;
		@include breakpoint(pmd) {
			padding: 0 0 0 24px;
		}
	}
	.accent-p {
		margin-bottom: 10px;
	}
}
.tab4 {
	overflow: hidden;
	background-color: $color-white;
	@include breakpoint(pmd) {
		display: flex;
		justify-content: space-between;
		height: 250px;
	}
	@include breakpoint(plg) {
		height: 190px;
	}
	.descr-part {
		flex-grow: 1;
		padding: 20px 0;
		@include breakpoint(plg) {
			display: flex;
		}
		.elem1, .elem2, .elem3 {
			padding: 0 20px;
			@include breakpoint(pmd) {
				padding: 0 30px;
			}
		}
		.elem-gr {
			width: 100%;
			padding-bottom: 20px;
			@include breakpoint(plg) {
				width: 58%;
				padding-bottom: 0;
			}
			@include breakpoint(pxl) {
				display: flex;
			}
		}
		.elem1 {
			width: 100%;
			@include breakpoint(pxl) {
				width: 30%;
			}
		}
		.elem2 {
			width: 100%;
			@include breakpoint(pxl) {
				width: 70%;
			}
		}
		.elem3 {
			display: flex;
			width: 100%;
			@include breakpoint(plg) {
				width: 42%;
				border-left: 2px solid $color5;
			}
			.text-el {
				width: 50%;
				padding-top: 20px;
				border-top: 1px solid $color5;
				@include breakpoint(plg) {
					width: 105px;
					padding-top: 0;
					border-top: none;
					margin-right: 30px;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.title {
			color: $color1;
			font-family: $font2;
			font-size: 32px;
			line-height: 1;
			margin-bottom: 5px;
		}
	}
	.img-wr {
		flex-shrink: 0;
		width: 100%;
		height: 250px;
		@include breakpoint(pmd) {
			order: 1;
			width: 224px;
			height: auto;
		}
		@include breakpoint(pxl) {
			width: 390px;
		}
	}
}
// </tabs
// tables-block
.tables-block {
	padding: 30px 0;
	@include breakpoint(plg) {
		padding: 40px 0;
	}
	&.v1 {
		padding: 60px 0 30px 0;
		@include breakpoint(plg) {
			padding: 40px 0;
		}
	}
	h2 {
		margin-bottom: 10px;
		@include breakpoint(pmd) {
			margin-bottom: 30px;
		}
	}
}
// </tables-block
// text-section
.text-section {
	padding: 30px 0;
	@include breakpoint(plg) {
		padding: 40px 0;
	}
}
.text-block {
	h2, h3 {
		line-height: 1;
	}
	h2 {
		padding-bottom: 10px;
	}
	ul {
		li {
			margin-left: 40px;
			+ li {
				margin-top: 20px;
			}
			&:before {
				content: '';
				display: inline-block;
				width: 26px;
				vertical-align: middle;
				border-top: 4px solid $color1;
				margin: 0 14px 0 -40px;
			}
		}
	}
	* {
		+ h2 {
			margin-top: 40px;
		}
		+ h3 {
			margin-top: 40px;
		}
		+ p {
			margin-top: 20px;
		}
		+ ul {
			margin-top: 20px;
		}
	}
}
// </text-section
// prod-cards
.prod-cards {
	padding: 30px 0;
	@include breakpoint(pmd) {
		padding: 40px 0;
	}
	@include breakpoint(pxl) {
		padding: 80px 0 30px 0;
	}
	h2 {
		margin-bottom: 20px;
		@include breakpoint(pmd) {
			margin-bottom: 30px;
		}
	}
}
.prod-card {
	overflow: hidden;
	background-color: $color-white;
	border-radius: 6px;
	.prod-img-bl {
		height: 220px;
	}
	.descr-bl {
		height: 180px;
		overflow: hidden;
		padding: 20px;
		@include breakpoint(psm) {
			height: 220px;
		}
		@include breakpoint(plg) {
			height: 200px;
		}
		.title {
			font-family: $font2;
			font-size: 32px;
			line-height: 1;
			margin-bottom: 10px;
		}
		.descr {
			overflow: hidden;
			max-height: 45px;
		}
		.price-segm {
			font-size: 20px;
			margin-top: 20px;
		}
	}
	.qty-bl {
		padding: 10px 20px 20px 20px;
		.input-group {
			display: flex;
			background-color: $color7;
			padding: 7px 14px;
			border-radius: 6px;
			input {
				width: 60%;
				height: 32px;
				font-family: $font2;
				font-size: 32px;
				background-color: transparent;
				@include breakpoint(psm) {
					width: 40%;
				}
				@include breakpoint(pmd) {
					width: 50%;
				}
				@include breakpoint(plg) {
					width: 60%;
				}
			}
			.field-select {
				width: 40%;
				@include breakpoint(psm) {
					width: 60%;
				}
				@include breakpoint(pmd) {
					width: 50%;
				}
				@include breakpoint(plg) {
					width: 40%;
				}
			}
			.jq-selectbox__select {
				height: 32px;
				padding: 0 0 0 15px;
				border-bottom: none;
				border-left: 1px solid $color5;
			}
			.jq-selectbox__dropdown {
				width: 100%;
			}
		}
	}
	.click-bl {
		display: flex;
		color: $color-white;
		background-color: $color-black;
		span {
			width: 50%;
			text-align: center;
			font-size: 17px;
			font-weight: 700;
			line-height: 41px;
			text-transform: uppercase;
		}
		.blue-btn {
			width: 50%;
			height: 41px;
		}
	}
}
.more-btn {
	width: 100%;
	height: 52px;
	margin-top: 10px;
}
.more-section {
	display: none;
}
// </prod-cards
// section-with-img
.section-with-img {
	padding: 30px 0;
	@include breakpoint(pmd) {
		padding: 40px 0;
	}
	@include breakpoint(pxl) {
		padding: 80px 0 40px 0;
	}
	h2 {
		margin-bottom: 25px;
		@include breakpoint(pxl) {
			width: 50%;
		}
	}
	.img {
		width: 100%;
		height: 350px;
		border-radius: 6px;
		margin: 50px 0 20px 0;
		@include breakpoint(plg) {
			width: 50%;
			height: 700px;
			float: right;
			margin: 0 0 20px 20px;
		}
	}
}
.show-btn {
	display: flex;
	align-items: center;
	color: $color1;
	font-weight: 700;
	text-transform: uppercase;
	background-color: transparent;
	margin-top: 20px;
	img {
		margin-right: 10px;
	}
	.hd {
		display: none;
	}
	&.active {
		.sh {
			display: none;
		}
		.hd {
			display: inline-block;
		}
		img {
			transform: rotate(180deg);
		}
	}
}
.hidden-text {
	display: none;
}
// </section-with-img
// order-block
.order-block {
	padding: 10px 0 30px 0;
	@include breakpoint(pmd) {
		padding: 10px 0 40px 0;
	}
	@include breakpoint(pxl) {
		padding: 30px 0 40px 0;
	}
	.img-wrap {
		height: 350px;
		margin-bottom: 10px;
		@include breakpoint(pmd) {
			height: 400px;
		}
		@include breakpoint(plg) {
			height: 415px;
			margin-bottom: 0;
		}
	}
}
// </order-block
// map-section
.map-section {
	padding: 80px 0 0 0;
	h2 {
		margin-bottom: 40px;
	}
	.map {
		height: 580px;
		@include breakpoint(pxl) {
			height: 760px;
		}
	}
	.address-wr {
		.address-bl {
			width: 100%;
			background-color: $color-white;
			padding: 30px 20px;
			margin-top: 392px;
			@include breakpoint(psm) {
				width: 255px;
				margin-top: 80px;
			}
			@include breakpoint(pxl) {
				width: 495px;
				padding: 50px 40px;
				margin-top: 120px;
			}
			.accent-p {
				margin-bottom: 10px;
			}
			.line {
				margin: 30px 0;
				border-top: 1px solid $color5;
			}
			.clbbtn {
				width: 100%;
				height: 58px;
				margin: 5px 0 30px 0;
				@include breakpoint(pxl) {
					width: 215px;
				}
			}
		}
	}
}
// </map-section
/* </main */


/* footer */
footer {
	color: $color-white;
	background-color: $color-black;
	.top-footer {
		padding: 235px 0 60px 0;
		@include breakpoint(psm) {
			padding: 60px 0;
		}
		@include breakpoint(plg) {
			padding: 80px 0;
		}
		@include breakpoint(pxl) {
			padding: 120px 0;
		}
	}
	.btm-footer {
		padding: 22px 0;
		border-top: 2px solid $color5;
	}
	h4 {
		margin-bottom: 25px;
	}
	.menu-list {
		@include breakpoint(plg) {
			column-count: 2;
		}
		li {
			+ li {
				margin-top: 12px;
			}
		}
	}
	.simple-ul {
		li {
			&::before {
				content: '—';
				display: inline-block;
				margin-right: 8px;
			}
		}
	}
	.hor-ul {
		display: flex;
		align-items: center;
		color: $color5;
		font-size: 15px;
		li {
			margin-right: 40px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
/* </footer */


/* modal */
.close-btn {
	background-color: transparent;
}
.icon-cross {
	width: 16px;
	height: 16px;
	display: inline-block;
	fill: none;
	stroke: $color5;
	stroke-width: 3;
	stroke-linecap: round;
	stroke-linejoin: round;
	transition: 0.2s;
	&:hover {
		stroke: $color1;
	}
}
.modal-backdrop {
	background-color: $color-black;
	&.show {
		opacity: 0.9;
	}
}
.modal-dialog {
	@include breakpoint(psm) {
		max-width: 360px;
	}
}
.modal-header {
	padding: 30px 30px 10px 30px;
	border: none;
	border-radius: 0;
}
.modal-title {
	line-height: 1;
}
.modal-body {
	padding: 0;
	.top-part {
		padding: 10px 30px 40px 30px;
	}
	.blue-btn {
		width: 100%;
		height: 64px;
	}
}
/* </modal */